<template>
  <Header />
  <slot />
  <Footer />
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";

import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
export default {
  components: { Header, Footer },
  setup() {
    const route = useRoute();

    return {
      meta: computed(() => route.meta),
    };
  },
};
</script>
