<template>
  <header class="header-d">
    <div class="header-d__top flex justify-between">
      <div class="info">
        <span>{{ trans('frontend.managed by') }}</span>
        <img src="../assets/img/logo/icon-top.svg" alt="" />
      </div>
      <div class="action flex items-center">
        <div
          class="action__search flex items-center cursor-pointer"
          @click="activeSearch = !activeSearch"
        >
          <img src="../assets/img/icons/search.svg" alt="search" />
          <span>{{ trans('frontend.button.search') }}</span>
        </div>
        <translate/>
      </div>
    </div>
    <div class="menu-fix">
      <div class="header-content flex">
        <div class="logo flex items-center cursor-pointer">
          <router-link :to="'/'"
            ><img src="../assets/img/logo/logo-pc.svg" alt="logo"
          /></router-link>
        </div>
        <div class="menu-group">
          <div class="menu flex items-center">
            <ul class="menu-b flex mb-0" v-if="dataMenu">
              <li
                v-for="(item, index) in dataMenu"
                :key="index"
                :class="[
                  index === menuActive ? 'item active' : 'item',
                  'item-m-' + index,
                ]"
              >
                <router-link
                  class="menu-p"
                  :to="item.link"
                  @click="
                    activeMenuN(
                      index,
                      index === 0 || index === 4 || index === 5 ? true : 0
                    )
                  "
                  >{{ item.name }}</router-link
                >
                <ul class="sub-menu">
                  <li
                    v-for="(sub, index2) in item.sub"
                    :key="index2"
                    @click="activeMenuP(index, index2)"
                  >
                    <router-link
                      :class="
                        index === menuActive && index2 === subActive
                          ? 'active'
                          : ''
                      "
                      :to="sub.link + (sub.folder_id ? ('?id='+ sub.folder_id) : '')"
                      >{{ sub.name }}</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="action flex items-center">
            <div
              class="action__menu-detail flex items-center cursor-pointer"
              @click="activeMenu = !activeMenu"
            >
              <img
                class="menu-icon"
                src="../assets/img/header/menu.svg"
                alt="menu"
              />
              <span>MENU</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //popupmenu -->
    <!-- //popupsearch -->
    <popupMenu
      :activeMenuP="activeMenu"
      :activeSearchP="activeSearch"
      @setPopupSearch="setPopupSearch"
      @setPopupMenu="setPopupMenu"
    />
  </header>
</template>
<script>
/* global $ */
import { computed } from "vue";
import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import popupMenu from "@/components/popup/popupMenu.vue";
import translate from "@/components/translate/translate.vue";
export default {
  components: {
    popupMenu,
    translate,
  },
  data() {
    return {
      activeMenu: false,
      activeSearch: false,
    };
  },
  setup() {
    const router = useRoute();
    return {
      meta: computed(() => router.meta),
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      // $(".menu-toggle .item").click(function (event) {
      //   if (event.target.classList.value.includes("active")) {
      //     $(".menu-toggle .item").removeClass("active");
      //     return;
      //   }
      //   $(".menu-toggle .item").removeClass("active");
      //   event.target.classList.toggle("active");
      // });
      // $(".sub-menu li").click(function (event2) {
      //   $(".menu-b li a").removeClass("active");
      //   event2.target.classList.toggle("active");
      // });
      $(window).bind("scroll", function () {
        if ($(window).scrollTop() > 200) {
          $(".header-d .header-content").addClass(
            "menu-fixed fadeInOpacityMenu"
          );
        }
        else {
          $(".header-d .header-content").removeClass(
            "menu-fixed fadeInOpacityMenu"
          );
        }
      });
    });
  },
  computed: {
    ...mapGetters(["menuActive", "subActive", "dataMenu"]),
  },
  methods: {
    activeMenuP(index, sub) {
      this.activeMenuMain(index);
      this.$store.commit("setMenuActive", index);
      this.$store.commit("setSubActive", sub);
    },
    activeMenuN(index, sub) {
      this.activeMenuMain(index);

      this.$store.commit("setMenuActive", index);
      if ((index === 0 || index === 4 || index === 5) && sub === true) {
        this.$store.commit("setSubActive", true);
      } else {
        this.$store.commit("setSubActive", sub);
      }
    },
    activeMenuMain(index) {
      $(".menu-b .item").removeClass("active");
      $(`.menu-b .item-m-${index}`).addClass("active");
    },
    setPopupMenu(data) {
      this.activeMenu = data;
    },
    setPopupSearch(data) {
      this.activeSearch = data;
    },
  },
};
</script>
